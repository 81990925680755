<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/artigos">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
          <div class="d-flex align-center">
            <v-btn
              small
              depressed
              color="fmq_gray"
              dark
              @click="openPDF(artigo.hash)"
            >
              <v-icon small class="mr-2"> mdi-file </v-icon>Visualizar
              Artigo</v-btn
            >
          </div>
        </div>
        <ArtigoFormulario
          @send="editar"
          :listaDeTags="listaDeTags"
          :listProducts="listProdutos"
          :loadingBtn="loadingBtn"
          :item="artigo"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Artigo editado com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { listarTags } from "@/services/tags.js";
import { listarProdutos } from "@/services/produtos.js";
import ArtigoFormulario from "@/components/artigos/ArtigoFormulario.vue";
import { exibirArtigo, editarArtigo } from "@/services/artigos.js";
export default {
  name: "ArtigoEditar",
  components: {
    ArtigoFormulario,
  },
  data: () => ({
    artigo: {},
    loading: false,
    breadcrumbs: [
      {
        text: "Artigos",
        disabled: false,
        to: "/artigos",
      },
      {
        text: "Editar Artigo Científico",
        disabled: true,
        to: "",
      },
    ],
    listaDeTags: [],
    listProdutos: [],
    success: false,
    error: false,
    messageError: null,
    loadingBtn: false,
  }),
  beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.exibir();
    this.listTags();
    this.listProducts();
  },
  methods: {
    async exibir() {
      try {
        this.loading = true;
        const resp = await exibirArtigo(this.$route.params.id);
        this.artigo = resp.data;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async editar(event) {
      event.append("_method", 'PUT');
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarArtigo(this.$route.params.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "Artigos" });
    },
    async listTags() {
      const resp = await listarTags();
      this.listaDeTags = resp.data;
    },
    async listProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    openPDF(hash) {
      window.open(
        process.env.VUE_APP_API + "donwload/artigos/" + hash,
        "_blank"
      );
    },
  },
};
</script>

<style></style>
